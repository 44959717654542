import React, { useRef, useState } from "react";
import Slider from "react-slick";
import image1 from './../assets/image1.png'
import image2 from './../assets/image2.png'
import image3 from './../assets/image3.png'

const CyclingNews =(props)=>{
    const {windowWidth}=props
    const sliderRef = useRef(null);
    const [btnBgColor, setBtnBgColor] = useState(true)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: windowWidth>991 ? 3 : windowWidth>534 ? 2 : 1,
        slidesToScroll: 1,
        autoplay: true,
       
        autoplaySpeed: 3000 // Set the time (in milliseconds) between each slide transition
    };

    const partners = [
        { name: "VIDEO: Guide on how to lock a bike", logo: image1 },
        { name: "How to get stronger legs for cycling", logo: image2 },
        { name: "Best bike locks and how to keep your bike safe", logo: image3 },
        { name: "VIDEO: Guide on how to lock a bike", logo: image1 },
        { name: "How to get stronger legs for cycling", logo: image2 },
        { name: "Best bike locks and how to keep your bike safe", logo: image3 },

    ];

    const handleNext = () => {
        sliderRef.current.slickNext();
        setBtnBgColor(true)
    };

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
        setBtnBgColor(false)
    };

    
    return (
        <div className="cycling-news pt-5 mt-5">
             <div className="container">
                <div className="header-partner-container d-flex-velo-cycling mb-2">

                    <p className="title-cycling-typo">Cycling news, gear & tips</p>
                    <div>
                        <button className={"ml-velo rounded-btn cycling-arrow" + (!btnBgColor ? ' btn-bg-velo' : ' btn-outline-velo')} onClick={handlePrevious}>
                            <i class="fa-solid fa-arrow-left"></i>
                        </button>

                        <button className={" rounded-btn cycling-arrow" + (btnBgColor ? ' btn-bg-velo' : ' btn-outline-velo')} onClick={handleNext}>

                            <i class="fa-solid fa-arrow-right"></i>
                        </button>

                    </div>
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {partners.map((partner) => (
                        <div key={partner.name} className="cycling-container">
                            <div className="card-cycling">
                                <img src={partner.logo} alt={partner.name} className="h-100 w-100" />
                                <div className="cycling-footer-content">
                                    <p>{partner.name}</p>
                                    <button className="cycling-btn">Read</button>
                                </div>

                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>

    )
}

export default CyclingNews