
import React from "react";
import HomePage from "./pages/HomePage";
import BikeInsuranceQuote from "./components/BikeInsuranceQuote";
import BikeInsurance from './components/BikeInsurance'
import AppLayout from "./pages/AppLayout";



const routes= [
    {path: '/', element:<HomePage/>},
    {
        
        path: "/",
        element: <AppLayout/>,
        children:[
    
            {path:'bikeInsurance',element:<BikeInsurance/>},
            {path:'bikeInsuranceQuote',element:<BikeInsuranceQuote/>}
        ]
    },


]

export {routes}