import React, { useState } from "react"
import { Link } from "react-router-dom";
import "../App.css"
import logo from './../assets/logo.png'

const Header = () => {
    const [showNav, setNav] = useState(false)
    return (
        <div className="container">

            <nav className="navbar d-flex-velo position-relative ">
                <img src={logo} className="" alt="" />

                <ul className="d-flex list-unstyled  ">
                    <li className="list-style-none position-relative ">
                        <Link className="text-decoration-none text-dark normal-link-typo" href="#">Services

                            <span className="p-1">

                                <i className="fa fa-chevron-down position-absolute" style={{ top: '50%', right: '', transform: 'translateY(-50%)' }}></i>
                            </span>

                        </Link>
                    </li>
                    <li>
                        <Link className="text-decoration-none text-dark normal-link-typo" href="#">News & Blog </Link>
                    </li>
                    <li className="position-relative">
                        <Link className="text-decoration-none text-dark normal-link-typo" href="#">About

                            <span className="p-1">

                                <i className="fa fa-chevron-down position-absolute" style={{ top: '50%', right: '', transform: 'translateY(-50%)' }}></i>
                            </span>
                        </Link>
                    </li>
                    <li><Link className="text-decoration-none text-dark normal-link-typo" href="#">Contact</Link></li>
                    <li><Link className="text-decoration-none btn-velo normal-link-typo btn-outline-velo" href="#">Log In</Link></li>
                </ul>

                {/* mobile device navigation  start here */}
                <div className={showNav ? 'mobile-device-nav-block' : 'mobile-device-nav'} >
                    <ul className="small-dev-flex">
                        <div className="mobile-device-header">
                            <img src={logo} alt="" />
                            <button class="close-icon" onClick={() => { setNav(!showNav) }}><i class="fa fa-times"></i></button>



                        </div>
                        <div className="mobile-device-content">

                            <p>Services</p>
                            <p>News & Blog</p>
                            <p>About</p>
                            <p>Contact</p>
                        </div>

                    </ul>


                </div>

                {/* mobile device navigation end here */}
                <div className="small-nav">
                    <a href="Log In">Log In</a>
                    <button class="hamburger-icon" onClick={() => { setNav(!showNav) }}><i class="fa fa-bars"></i></button>


                </div>




            </nav>
        </div>

    )
}

export default Header
