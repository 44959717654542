import React, { useState, useEffect, Link } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import shape4 from './../assets/shape4.png';
import Switch from 'react-switch';
import paymentIcon from './../assets/payment-icons.png'


import './../App.css'



const BikeInsuranceQuote = () => {
    const [currentForm, setCurrentForm] = useState(0)
    const [btnBgColor, setBtnBgColor] = useState(true)
    const [checked, setChecked] = useState(true);
    const [hearChecked, setHearChecked] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dobDate, setDobDate] = useState(new Date());

    const step0ValidationSchema = Yup.object({
        policyStartDate: Yup.date().required('Policy start date is Required'),
        excessAmount: Yup.object().required('Excess Amount is Required'),
        bikes: Yup.array()
            .of(
                Yup.object({
                    bikeType: Yup.object().required('Bike Type is Required'),
                    bikeMake: Yup.object().required('Bike Make is Required'),
                    bikeValue: Yup.string().required('Bike value is Required'),
                    bikeSerial: Yup.string().required('Bike Serial No is Required'),
                })
            )
            .required('Must have at least one bike'),



    })

    const step1ValidationSchema = Yup.object({
        title: Yup.object().required('Title is Required'),
        firstName: Yup.string().required('First Name is Required'),
        lastName: Yup.string().required('Last Name is Required'),
        contactNumber: Yup.string().required('Contact Number is Required'),
        postcode: Yup.string().required('Postcode is Required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        confirmEmail: Yup.string()
            .oneOf([Yup.ref('email'), null], 'Emails must match')
            .required('Confirm Email is required'),
        address1: Yup.string().required('Address is Required'),
        city: Yup.string().required('City is Required'),

    })

    const step2ValidationSchema = Yup.object({
        cycle_breakdown_cover: Yup.object().required('Cycle breakdown is Required'),
        worldwide_cover: Yup.object().required('Worldwide cover is Required'),
        personal_accident_cover: Yup.object().required('Personal accident cover is Required'),
        legal_expenses_cover: Yup.object().required('Legal expenses cover is Required'),
        replacement_hire: Yup.object().required('Replacement Hire is Required'),

    })
    const step3ValidationSchema = Yup.object().shape({
        confirm_selection: Yup.boolean().oneOf([true], 'Please confirm your selection'),
    });

    const step5ValidationSchema = Yup.object({
        cardNumber: Yup.string()
            .required('Card number is required'),

        expiryDate: Yup.string()
            .required('Expiry date is required')
            .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Expiry date must be in MM/YY format'),
        cvc: Yup.string().required('CVC is required'),

    })



    const validationSchema = Yup.object({
        step0: step0ValidationSchema,
        step1: step1ValidationSchema,
        step2: step2ValidationSchema,
        step3: step3ValidationSchema,
        step5: step5ValidationSchema

    });
    const formatCardNumber = (value) => {
        // Remove all non-digit characters
        const cardNumber = value.replace(/\D/g, '');

        // Add a space after every 4 digits
        let formattedNumber = '';
        for (let i = 0; i < cardNumber.length; i++) {
            if (i > 0 && i % 4 === 0) {
                formattedNumber += ' ';
            }
            formattedNumber += cardNumber.charAt(i);
        }

        return formattedNumber;
    };

    const handleCardNumberChange = (e) => {
        const formattedNumber = formatCardNumber(e.target.value);
        // Limit input to 12 characters
        const limitedNumber = formattedNumber.slice(0, 19);
        formik.setFieldValue('step5.cardNumber', limitedNumber);
    };

    const handleExpiryDateChange = (e) => {
        const input = e.target.value;
        const formattedInput = input
            .replace(/[^0-9]/g, '') // Remove non-digit characters
            .slice(0, 4); // Limit input to 4 characters

        let formattedDate = '';
        if (formattedInput.length >= 3) {
            formattedDate = formattedInput.slice(0, 2) + '/' + formattedInput.slice(2);
        } else {
            formattedDate = formattedInput;
        }

        formik.setFieldValue('step5.expiryDate', formattedDate);
    };


    const formik = useFormik({
        initialValues: {
            step0: {
                policyStartDate: selectedDate,
                excessAmount: '',

                bikes: [{
                    bikeType: '', bikeMake: '', bikeValue: '', bikeSerial: ''
                }],

            },
            step1: {

                dob: dobDate,
                title: '',
                firstName: '',
                lastName: '',
                contactNumber: '',
                postcode: '',
                email: '',
                confirmEmail: '',
                address1: '',
                address2: '',
                city: '',
            },
            step2: {

                cycle_breakdown_cover: '',
                worldwide_cover: '',
                personal_accident_cover: '',
                legal_expenses_cover: '',
                replacement_hire: '',
                hear_from_us: hearChecked,
            },
            step3: {
                confirm_selection: '',

            },
            step5: {

                cardNumber: '',
                expiryDate: '',
                cvc: '',
            }

        },

        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            alert(JSON.stringify(values, null, 2));
        },
    });

    const handleAddBike = () => {
        formik.setValues({

            ...formik.values,
            step0: {
                ...formik.values.step0,
                bikes: [
                    ...formik.values.step0.bikes,
                    { bikeType: '', bikeMake: '', bikeValue: '', bikeSerial: '' }
                ]
            }

        });
    };

    const handleRemoveBike = (index) => {
        const updatedBikes = [...formik.values.step0.bikes];
        updatedBikes.splice(index, 1);
        formik.setValues({
            ...formik.values,
            step0: {
                ...formik.values.step0,
                bikes: updatedBikes,
            }
        });
    };


    const options = [
        { value: "apple", label: "Apple" },
        { value: "banana", label: "Banana" },
        { value: "cherry", label: "Cherry" },
        { value: "grape", label: "Grape" },
        { value: "orange", label: "Orange" },
    ];

    const countries = [
        { name: 'USA', code: '+1', placeholder: "00 - 000 - 0000" },
        { name: 'Canada', code: '+1', placeholder: "00 - 0000 - 000" },
        { name: 'UK', code: '+44', placeholder: "00 - 000 - 0000" },
        // add more country options as needed
    ];
    const titles = [
        { value: 'Mr', label: "Mr" },
        { value: 'Mrs', label: "Mrs" },
        { value: 'Ms', label: "Ms" },
        { value: 'Miss', label: "Miss" },
        { value: 'Master', label: "Master" },
        { value: 'Dr', label: "Dr" },
        { value: 'Lord', label: "Lord" },
        { value: 'Lady', label: "Lady" },
        { value: 'Baron', label: "Baron" },
        { value: 'Baroness', label: "Baroness" },
    ]

    const YESorNO = [
        { value: 'Yes', label: "Yes" },
        { value: 'No', label: "No" },

    ]
    const personalAcidentOption = [
        { value: 'None', label: "None" },
        { value: '10000', label: "£ 10000" },
        { value: '20000', label: "£ 20000" },

    ]
    const replacementHireOption = [
        { value: 'None', label: "None" },
        { value: '500', label: "£ 500" },
        { value: '750', label: "£ 750" },
        { value: '1000', label: "£ 1000" },

    ]

    const excessAmountOptions = [
        { value: '0', label: "£ 0" },
        { value: '50', label: "£ 50" },
        { value: '100', label: "£ 100" },
        { value: '150', label: "£ 150" },
        { value: '200', label: "£ 200" },
        { value: '250', label: "£ 250" },
    ]

    const [bicycleValueCountry, setbicycleValueCountry] = useState(countries[0]);
    // const []



    const bicycleValue = (event) => {
        setbicycleValueCountry(countries.find(c => c.name === event.target.value));
    }

    function handleDateChange(date) {
        setSelectedDate(date);
    }

    function handleDobDateChange(date) {
        setDobDate(date);
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            outline: state.isFocused ? '' : 'none',
            border: '2px solid #B8F0E4',
            borderRadius: '10px',
            padding: '2px',
            boxShadow: state.isFocused ? '' : provided.boxShadow,

            borderColor: state.isFocused ? '#1ACDA7' : '#1ACDA7', // custom border color on focus
            '&:hover': {
                borderColor: '#1ACDA7', // custom border color on hover
            }, '&:active': {
                background: 'none',
                boxShadow: 'none',
                borderColor: '#1ACDA7',
                outline: "none"
            },

        }),
    };
    // console.log(selectedCountry)

    const handleNextForm = (event) => {
        event.preventDefault();

        if (formik.errors[`step${currentForm}`] == null && currentForm < 5) {
            setCurrentForm(currentForm + 1)
            setBtnBgColor(true)
        }

    }
    const handlePrevForm = (event) => {
        event.preventDefault();
        if (currentForm == 0) {

            return

        }
        else {
            setCurrentForm(currentForm - 1)
        }

    }


    //   console.log(formik.errors[`step${stepNumber}`]?.[fieldName]);

    //   console.log(formik.errors[`step${0}`])
    useEffect(() => {
        const touchedFields = Object.keys(formik.values).reduce((acc, key) => {
            acc[key] = true;
            return acc;
        }, {});
        formik.setTouched(touchedFields);
    }, []);



    const numDots = 6;
    return (
        <div className="Bike-insurance-quote-container">
            <div className="container">
                <div className=" bike-insurance-header-content ">
                    <div className="half-left-bike-header">
                        <p>Bike Insurance Quote About Your Cycle</p>
                        <div className="bike-insurance-price mt-3">

                            <div className="half-left-bottom">
                                <p className="bold-weight" >4.35€</p>
                                <p className="light-weight">10 Monthly Payments</p>
                            </div>
                            <div className="half-left-bottom">
                                <p className="bold-weight">40.30€</p>
                                <p className="light-weight">Per Year</p>
                            </div>
                        </div>

                    </div>
                    {/* <div className="col-md-2"></div> */}
                    <div className="bikeInsuranceQuteImg ">
                        <img src={shape4} className="h-100 w-100" alt="" />

                    </div>
                </div>
                <div className=" bike-insurance-content mt-4 mb-4">



                    <form onSubmit={formik.handleSubmit}>
                        <div className="bike-insurance-header">
                            <div className="bike-insurance-header-headline">
                                {[...Array(numDots)].map((_, index) => (
                                    <React.Fragment key={index}>
                                        <span className={`dot-nav ${index <= currentForm ? 'dot-curent' : ''}`}>  </span>
                                        {index >= 5 ? null : <span className={`dash-nav ${index <= currentForm - 1 ? 'dash-nav-current ' : ''}`}> </span>}
                                    </React.Fragment>
                                ))}
                            </div>
                            <div className="bikeInsurance-navigate-form-btn">

                                <button className={"ml-velo rounded-btn same-btn-design btn-outline-velo"} onClick={handlePrevForm}>
                                    <i class="fa-solid fa-arrow-left"></i>
                                </button>

                                <button className={" rounded-btn same-btn-design btn-outline-velo btn-bg-velo"}

                                    style={{
                                        opacity: formik.errors[`step${currentForm}`] != null ? 0.6 : 1,
                                        cursor: formik.errors[`step${currentForm}`] != null ? 'not-allowed' : 'pointer',
                                        pointerEvents: formik.errors[`step${currentForm}`] != null ? 'none' : 'auto',
                                        transition: "scale 0.5s ease-in-out",
                                        animation:
                                            !formik.errors[`step${currentForm}`]
                                                ? (!formik.isValid ? 'pulse 1s ease-in-out infinite' : '')
                                                : "none",


                                    }}


                                    onClick={handleNextForm}>

                                    <i class="fa-solid fa-arrow-right "></i>
                                </button>
                            </div>

                        </div>

                        {currentForm == 5 && (

                            <div className="form-container-parent1">
                                form5
                                <div>
                                    <img src={paymentIcon} alt="" />
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-6">
                                        <label htmlFor="cardNumber">Card Number</label>
                                        <input
                                            id="cardNumber"
                                            type="text"
                                            name="step5.cardNumber"
                                            onChange={handleCardNumberChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.step5.cardNumber}
                                            className="serial-no-input mt-1"
                                            placeholder="**** **** **** ****"
                                            max="12"
                                        />
                                        {formik.touched.step5?.cardNumber && formik.errors.step5?.cardNumber && (
                                            <div className="text-danger"> <small> {formik.errors.step5.cardNumber}</small></div>
                                        )}

                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="expiryDate">Expiry Date</label>
                                        <input
                                            id="expiryDate"
                                            name="expiryDate"
                                            type="text"
                                            onChange={handleExpiryDateChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.step5.expiryDate}
                                            className="serial-no-input mt-1"

                                            placeholder="MM/YY"
                                        />
                                        {formik.touched.step5?.expiryDate && formik.errors.step5?.expiryDate && (
                                            <div className="text-danger"><small> {formik.errors.step5.expiryDate}</small></div>
                                        )}

                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <label htmlFor="expiryDate">CVC  Code</label>
                                        <input
                                            id="cvc"
                                            name="step5.cvc"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.step5.cvc}
                                            className="serial-no-input mt-1"

                                            placeholder="****"
                                            maxLength={4}

                                        />
                                        {formik.touched.step5?.cvc && formik.errors.step5?.cvc && (
                                            <div className="text-danger"> <small> {formik.errors.step5.cvc} </small></div>
                                        )}

                                    </div>
                                </div>


                                <hr /></div>
                        )}
                        {currentForm == 4 && (

                            <div className="form-container-parent1 mt-3">
                                <p>We have not provided you with a personal recommendation as to whether this policy is suitable for your specific needs. This product is designed to meet the demands and needs of those who wish to insure specific risks relating to their bike(s) against accidental damage, theft, public liability and legal expenses.</p>

                                <p>
                                    Any insurance purchased from VeloLife will be subject to the terms and conditions which are detailed in the <a href="" className="text-decoration-none" > Policy wording</a>. To assess whether this policy meets your needs and requirements, you should read our;
                                </p>

                                <p className="paragraph-with-icon"><a href="#" className="text-decoration-none" >Terms of Business</a> </p>
                                <p className="paragraph-with-icon"><a href="#" className="text-decoration-none">Insurance Product Information Document (IPID)</a> </p>
                                <p className="paragraph-with-icon"><a href="#" className="text-decoration-none" >Policy wording</a> </p>


                                <p className="larger-bicycle-insurance-typo" >12 Months bicycle insurance</p>

                                <div className="bicycle-insurance-box">
                                    <p>£ 36.57</p>
                                    <p>Single Payment</p>
                                    <p>Selected</p>
                                </div>

                                <p className="mt-4 text-center">

                                    <small>
                                        Premium includes 12% Insurance Premium Tax. Monthly payments include 7.9% interest spread over 10 equal payments. Policy wording & IPID

                                    </small>
                                </p>








                                <hr /></div>
                        )}

                        {currentForm == 3 && (

                            <div className="form-container-parent1 mt-3">

                                <p className="paragraph-with-icon">You are aged 18 or over</p>

                                <p className="paragraph-with-icon">Your are a permanent resident of the United Kingdom and your permanent address is within the UK, Isle of Man or Channel Islands</p>

                                <p className="paragraph-with-icon">Your Bike is owned by you</p>


                                <p className="paragraph-with-icon">Your bike is in good working condition at the Start Date of this policy</p>

                                <p className="paragraph-with-icon" >You have read and understood the requirement for your bike to be locked securely with an approved lock relevant to the value of your bike when left unattended away from your home</p>

                                <p className="paragraph-with-icon">You have evidence to show your bike;</p>

                                <div className="paragraph-with-icon-indent">
                                    <p className="paragraph-with-icon-child">Was purchased as new or second hand from a UK VAT Registered (or equivalent if purchased overseas) cycle retailer; and/or</p>

                                    <p className="paragraph-with-icon-child">
                                        Was provided to you as an insurance claim replacement, and/or;
                                    </p>

                                    <p className="paragraph-with-icon-child">
                                        Was gifted to you (suitable evidence would be a gift receipt or original purchase receipt issued to the Purchaser of your bike)
                                    </p>

                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-10">
                                        By selecting Yes you confirm the above statements are true
                                        <div>
                                            <a href="#" className="text-decoration-none">Policy wording </a> & <a href="#" className="text-decoration-none"> IPID</a>
                                        </div>

                                    </div>
                                    <div className="col-md-2">
                                        <label style={{ display: 'flex', alignItems: 'center' }}>
                                            <Switch
                                                checked={checked}
                                                onChange={() => {
                                                    setChecked(!checked);
                                                    formik.setFieldValue('step3.confirm_selection', !checked);
                                                }}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                onColor="#1acda7"
                                                offColor="#d3d3d3"
                                                height={40}
                                                width={80}
                                                checkedHandleIcon={<div style={{ marginLeft: '7px', marginTop: '5px' }}>Yes</div>}
                                                uncheckedHandleIcon={<div style={{ marginLeft: '7px', marginTop: '5px' }}>No</div>}
                                            />
                                        </label>


                                    </div>
                                    {!checked ? <p className="text-danger mt-3" >If you do not accept the Terms & Conditions, we will be unable to offer you a policy.</p> : ''}
                                </div>


                                <hr /></div>
                        )}

                        {currentForm == 0 && (

                            <div className="form-container-parent1">
                                form0
                                <div class="row">
                                    <div class="col-md-6 ">

                                        <div className="policy-date-container">

                                            <label htmlFor="">Policy Start Date</label>
                                            <DatePicker selected={selectedDate} onChange={handleDateChange} format="yyyy-MM-dd" className="date-picker-input mt-1" />
                                        </div>
                                        {formik.errors.step0?.policyStartDate && formik.touched.step0?.policyStartDate && (
                                            <div className="text-danger"> <small> {formik.errors.step0.policyStartDate}</small></div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="">Excess Amount</label>
                                        <Select
                                            onBlur={formik.handleBlur}
                                            styles={customStyles}
                                            value={formik.values.step0.excessAmount}
                                            options={excessAmountOptions}
                                            isClearable
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue("step0.excessAmount", selectedOption);
                                            }}
                                            className="mt-1"
                                            placeholder="Please Select the Excess Amount"
                                        />
                                        {formik.errors.step0?.excessAmount && formik.touched.step0?.excessAmount && (
                                            <div className="text-danger"> <small> {formik.errors.step0.excessAmount} </small></div>
                                        )}
                                    </div>

                                </div>



                                {formik.values.step0?.bikes.map((bike, index) => (

                                    <div key={index}>
                                        <hr />
                                        <div className="bike-headline-container">

                                            <h3>Bike {index + 1} Detail</h3>

                                            {formik.values.step0.bikes.length > 1 && (
                                                <button type="button" className="remove-cycle-btn" onClick={() => handleRemoveBike(index)}><i class="fas fa-times"></i></button>

                                            )}

                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor={`step0.bikes[${index}].bikeType`}>
                                                        Bycicle Type:
                                                    </label>
                                                    <Select
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.step0.bikes[index].bikeType}
                                                        options={options}
                                                        id={`step0.bikes[${index}].bikeType`}
                                                        name={`step0.bikes[${index}].bikeType`}
                                                        styles={customStyles}
                                                        className=" mt-1"
                                                        isClearable
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue(`step0.bikes[${index}].bikeType`, selectedOption);
                                                        }}
                                                        placeholder="Please Select the bike "
                                                    />

                                                    {formik.touched.step0?.bikes?.[index]?.bikeType && formik.errors.step0?.bikes?.[index]?.bikeType && (
                                                        <div className="text-danger"> <small> {formik.errors.step0.bikes[index].bikeType}</small></div>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">

                                                    <label>
                                                        Bycicle Make:
                                                    </label>
                                                    <Select
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.step0.bikes[index].bikeMake}
                                                        styles={customStyles}
                                                        options={options}
                                                        name={`step0.bikes[${index}].bikeMake`}
                                                        className="mt-1"
                                                        isClearable
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue(`step0.bikes[${index}].bikeMake`, selectedOption);
                                                        }}
                                                        placeholder="Please Select the Bycicle Make "
                                                    />

                                                    {formik.touched.step0?.bikes?.[index]?.bikeMake && formik.errors.step0?.bikes?.[index]?.bikeMake && (
                                                        <div className="text-danger"><small> {formik.errors.step0.bikes[index].bikeMake} </small></div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div class="col-md-6">
                                                <label htmlFor="">Bicycle value</label>
                                                <div class="phone-input mt-1">
                                                    <select value={bicycleValueCountry.name} onChange={bicycleValue} style={{ width: '20%' }}>
                                                        {countries.map((country, index) => (
                                                            <option key={index} value={country.name}> {country.name} </option>
                                                        ))}
                                                    </select>
                                                    <input type="tel"
                                                        placeholder={bicycleValueCountry.placeholder}
                                                        name={`step0.bikes[${index}.bikeValue]`}
                                                        value={formik.values.step0.bikes[index].bikeValue}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur} style={{ width: '80%' }} />

                                                </div>
                                                {formik.touched.step0?.bikes?.[index]?.bikeValue && formik.errors.step0?.bikes?.[index]?.bikeValue && (
                                                    <div className="text-danger"> <small> {formik.errors.step0.bikes[index].bikeValue}</small></div>
                                                )}
                                            </div>
                                            <div className="col-md-6">

                                                <label>
                                                    Bycicle Serial No:
                                                </label>
                                                <div className="serial-no-container">
                                                    <input
                                                        type="text"
                                                        name={`step0.bikes[${index}].bikeSerial`}
                                                        value={formik.values.step0.bikes[index].bikeSerial}
                                                        onChange={formik.handleChange}
                                                        className="serial-no-input mt-1"
                                                        placeholder="BIK31123"
                                                    />

                                                </div>
                                                {formik.touched.step0?.bikes?.[index]?.bikeSerial && formik.errors.step0?.bikes?.[index]?.bikeSerial && (
                                                    <div className="text-danger"> <small> {formik.errors.step0.bikes[index].bikeSerial}</small></div>
                                                )}
                                            </div>
                                        </div>






                                    </div>
                                ))}
                                <div className="btn-signup-container">

                                    <button type="button" className="btn-insurance-add-velo btn-insurance-outline-velo mt-3" onClick={handleAddBike}>
                                        Add Bike <span><i class="fas fa-plus"></i>
                                        </span>
                                    </button>
                                </div>
                                <hr /></div>
                        )}


                        {currentForm == 1 && (

                            <div className="form-container-parent2">
                                form1
                                <div class="row mt-2">

                                    <div class="col-md-6">
                                        <label htmlFor="">Title</label>

                                        <Select
                                            onBlur={formik.handleBlur}
                                            styles={customStyles}
                                            value={formik.values.step1.title}
                                            options={titles}
                                            isClearable
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue("step1.title", selectedOption);
                                            }}
                                            className='mt-1'
                                            placeholder="Please Select the category"
                                        />
                                        {formik.errors.step1?.title && formik.touched.step1?.title && (
                                            <div className="text-danger"><small> {formik.errors.step1.title}</small></div>
                                        )}
                                    </div>
                                    <div class="col-md-6 ">

                                        <div className="policy-date-container">

                                            <label htmlFor="">Date of Birth</label>
                                            <DatePicker selected={dobDate} onChange={handleDobDateChange} format="yyyy-MM-dd" className="date-picker-input mt-1" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">

                                    <div class="col-md-6">
                                        <label htmlFor="">First Name</label>

                                        <input
                                            type="text"
                                            id="firstName"
                                            name="step1.firstName"
                                            value={formik.values.step1.firstName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder='Your Name'
                                            className="serial-no-input mt-1"
                                        />
                                        {formik.errors.step1?.firstName && formik.touched.step1?.firstName && (
                                            <div className="text-danger"><small> {formik.errors.step1.firstName} </small></div>
                                        )}
                                    </div>
                                    <div class="col-md-6 ">

                                        <div className="policy-date-container">

                                            <label htmlFor="">Last Name</label>
                                            <input
                                                type="text"
                                                id="lastName"
                                                name="step1.lastName"
                                                value={formik.values.step1.lastName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder='Last Name'
                                                className="serial-no-input mt-1"
                                            />
                                            {formik.errors.step1?.lastName && formik.touched.step1?.lastName && (
                                                <div className="text-danger"><small> {formik.errors.step1.lastName}</small></div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">

                                    <div class="col-md-6">
                                        <label htmlFor="">Contact Number</label>
                                        <input
                                            type="text"
                                            id="contactNumber"
                                            name="step1.contactNumber"
                                            value={formik.values.step1.contactNumber}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder='Contact Number'
                                            className="serial-no-input mt-1"
                                        />
                                        {formik.errors.step1?.contactNumber && formik.touched.step1?.contactNumber && (
                                            <div className="text-danger"><small> {formik.errors.step1.contactNumber}</small></div>
                                        )}
                                    </div>
                                    <div class="col-md-6 ">

                                        <label htmlFor="">Postcode</label>
                                        <input
                                            type="text"
                                            id="postcode"
                                            name="step1.postcode"
                                            value={formik.values.step1.postcode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder='Postcode'
                                            className="serial-no-input mt-1"
                                        />
                                        {formik.errors.step1?.postcode && formik.touched.step1?.postcode && (
                                            <div className="text-danger"><small> {formik.errors.step1.postcode}</small></div>
                                        )}



                                    </div>
                                </div>


                                <div class="row mt-2">

                                    <div class="col-md-6">
                                        <label htmlFor="">Address 1</label>
                                        <input
                                            type="text"
                                            id="address1"
                                            name="step1.address1"
                                            value={formik.values.step1.address1}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder='Address'
                                            className="serial-no-input mt-1"
                                        />

                                        {formik.errors.step1?.address1 && formik.touched.step1?.address1 && (
                                            <div className="text-danger"><small> {formik.errors.step1.address1}</small></div>
                                        )}

                                    </div>
                                    <div class="col-md-6 ">

                                        <label htmlFor="">Address 2</label>
                                        <input
                                            type="text"
                                            id="address2"
                                            name="step1.address2"
                                            value={formik.values.step1.address2}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder='Address'
                                            className="serial-no-input mt-1"
                                        />

                                    </div>
                                </div>


                                <div class="row mt-2">

                                    <div class="col-md-6">
                                        <label htmlFor="">Email</label>
                                        <input
                                            type="text"
                                            id="email"
                                            name="step1.email"
                                            value={formik.values.step1.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder='Email'
                                            className="serial-no-input mt-1"
                                        />

                                        {formik.errors.step1?.email && formik.touched.step1?.email && (
                                            <div className="text-danger"><small> {formik.errors.step1.email}</small></div>
                                        )}

                                    </div>
                                    <div class="col-md-6 ">

                                        <label htmlFor="">Confirm Email</label>
                                        <input
                                            type="text"
                                            id="confirmEmail"
                                            name="step1.confirmEmail"
                                            value={formik.values.step1.confirmEmail}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder='Confirm Email'
                                            className="serial-no-input mt-1"
                                        />
                                        {formik.errors.step1?.confirmEmail && formik.touched.step1?.confirmEmail && (
                                            <div className="text-danger"><small> {formik.errors.step1.confirmEmail}</small></div>
                                        )}
                                    </div>
                                </div>


                                <div class="row mt-2">


                                    <div class="col-md-6">
                                        <label htmlFor="">City</label>
                                        <input
                                            type="text"
                                            id="city"
                                            name="step1.city"
                                            value={formik.values.step1.city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder='City'
                                            className="serial-no-input mt-1"
                                        />
                                        {formik.errors.step1?.city && formik.touched.step1?.city && (
                                            <div className="text-danger"> <small>{formik.errors.step1.city}</small></div>
                                        )}
                                    </div>
                                </div>


                                <hr /></div>
                        )}


                        {currentForm == 2 && (


                            <div className="form-container-parent3">
                                form2
                                <div class="row mt-2">
                                    <div class="col-md-6 ">

                                        <label htmlFor="">Cycle Breakdown Cover</label>

                                        <Select
                                            onBlur={formik.handleBlur}
                                            styles={customStyles}
                                            value={formik.values.step2.cycle_breakdown_cover}
                                            options={YESorNO}
                                            isClearable
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue("step2.cycle_breakdown_cover", selectedOption);
                                            }}
                                            className='mt-1'
                                            placeholder="Please Select the category"
                                        />
                                        {formik.errors.step2?.cycle_breakdown_cover && formik.touched.step2?.cycle_breakdown_cover && (
                                            <div className="text-danger"> <small> {formik.errors.step2.cycle_breakdown_cover} </small></div>
                                        )}
                                    </div>
                                    <div class="col-md-6">
                                        <label htmlFor="">Worldwide Cover</label>
                                        <Select
                                            onBlur={formik.handleBlur}
                                            styles={customStyles}
                                            value={formik.values.step2.worldwide_cover}
                                            options={YESorNO}
                                            isClearable
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue("step2.worldwide_cover", selectedOption);
                                            }}
                                            className='mt-1'
                                            placeholder="Please Select the category"
                                        />
                                        {formik.errors.step2?.worldwide_cover && formik.touched.step2?.worldwide_cover && (
                                            <div className="text-danger"> <small> {formik.errors.step2.worldwide_cover} </small></div>
                                        )}
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-6 ">

                                        <label htmlFor="">Personal Accident Cover</label>

                                        <Select
                                            onBlur={formik.handleBlur}
                                            styles={customStyles}
                                            value={formik.values.step2.personal_accident_cover}
                                            options={personalAcidentOption}
                                            isClearable
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue("step2.personal_accident_cover", selectedOption);
                                            }}
                                            className='mt-1'
                                            placeholder="Please Select the category"
                                        />
                                        {formik.errors.step2?.personal_accident_cover && formik.touched.step2?.personal_accident_cover && (
                                            <div className="text-danger"> <small> {formik.errors.step2.personal_accident_cover}</small></div>
                                        )}

                                    </div>
                                    <div class="col-md-6 ">

                                        <label htmlFor="">Replacement Hire</label>

                                        <Select
                                            onBlur={formik.handleBlur}
                                            styles={customStyles}
                                            value={formik.values.step2.replacement_hire}
                                            options={replacementHireOption}
                                            isClearable
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue("step2.replacement_hire", selectedOption);
                                            }}
                                            className='mt-1'
                                            placeholder="Please Select the category"
                                        />
                                        {formik.errors.step2?.replacement_hire && formik.touched.step2?.replacement_hire && (
                                            <div className="text-danger"> <small> {formik.errors.step2.replacement_hire}</small></div>
                                        )}
                                    </div>


                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-6 ">

                                        <label htmlFor="">Legal Expenses Cover £5</label>

                                        <Select
                                            onBlur={formik.handleBlur}
                                            styles={customStyles}
                                            value={formik.values.step2.legal_expenses_cover}
                                            options={YESorNO}
                                            isClearable
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue("step2.legal_expenses_cover", selectedOption);
                                            }}
                                            className='mt-1'
                                            placeholder="Please Select the category"
                                        />
                                        {formik.errors.step2?.legal_expenses_cover && formik.touched.step2?.legal_expenses_cover && (
                                            <div className="text-danger"> <small> {formik.errors.step2.legal_expenses_cover}</small></div>
                                        )}
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center">
                                        <a href="#" className="text-decoration-none" > Legal Expenses wording </a> & <a href="#" className="text-decoration-none">  IPID </a>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-10">
                                        <p className="title-form-typo">Want to hear from us?</p>
                                        <p>We’d love to keep in touch with exclusive offers and cycling tips and hints from VeloLife. We promise that we will never share your data with any third party (other than the necessary information in order to process your insurance policy(s), but if you don’t want to hear from us then please select No</p>
                                        <div>
                                            <a href="#" className="text-decoration-none">Policy wording </a> & <a hhref="#" className="text-decoration-none"> IPID</a>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label style={{ display: 'flex', alignItems: 'center' }}>
                                            <Switch
                                                checked={hearChecked}
                                                onChange={() => setHearChecked(!hearChecked)}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                onColor="#1acda7"
                                                offColor="#d3d3d3"
                                                height={40}
                                                width={80}
                                                checkedHandleIcon={<div style={{ marginLeft: '7px', marginTop: "5px" }}>Yes</div>}
                                                uncheckedHandleIcon={<div style={{ marginLeft: '7px', marginTop: "5px" }}>No</div>}
                                            // handleDiameter={40}
                                            />
                                        </label>

                                    </div>
                                </div>





                                <hr /></div>

                        )}




                        <div />
                        <div className="footer-content-insurance ">
                            <div>


                            </div>

                            <div className="signUp-btn-container">

                                <button
                                    type='submit'
                                    className="btn-signup-velo btn-bg-signup-velo"
                                    style={{
                                        transition: "scale 0.5s ease-in-out",
                                        animation:
                                            (formik.isValid ? 'pulse 1s ease-in-out infinite' : '')
                                        ,
                                    }}

                                > Sign Up</button>
                                
                                {/* <button>
                                {!formik.isValid ? 'validate the form first' : 'Sign Up'}  </button> */}

                            </div>

                        </div>
                    </form>




                </div>

            </div>
        </div>
    )
}

export default BikeInsuranceQuote;