import React from "react";
import qoute from './../assets/qoute.png'


const Qoute = () => {

    return (
        <div className="container">
           
            <div class="image-container mt-5">
                <img src={qoute} alt="Your image description" />
                <div className="qoute-overlay"></div>
                <div class="image-caption">
                    <h2 className="qoute-title">Get a Quote</h2>
                    <p className="qoute-description">Bike Insurance Quote About Your Cycle</p>

                    <div>

                    <button className="qoute-btn">Quick Quote</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Qoute