import React, { useEffect, useState } from "react";
import "./../App.css";

import '@fortawesome/fontawesome-free/css/all.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hero from "../components/Hero";
import Customer from "../components/Customer";
import CoverDetail from "../components/CoverDetail";
import KeyFeatures from "../components/KeyFeatures";
import Qoute from "../components/Quote";
import CyclingNews from "../components/CyclingNews";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";


const HomePage = () => {
    const [windowWidth,setWindowWidth]=useState(window.innerWidth)

    useEffect(()=>{
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  
    },[])
    return (
        <div className="App overflow-hidden">
            <Hero />
            {/* <Customer windowWidth={windowWidth} /> */}
            <CoverDetail windowWidth={windowWidth} />
         
            <KeyFeatures />
            <Testimonials/>
            <Qoute />
            <CyclingNews windowWidth={windowWidth} />
            <Customer windowWidth={windowWidth} />
            <Footer />



        </div>
    )
}

export default HomePage