import React, { useRef, useState } from "react";
import avatar1 from './../assets/avtar1.png'
import avatar2 from './../assets/avatar2.png'
import avatar3 from './../assets/avatar3.png'
import avatar4 from './../assets/avatar4.png'
import Slider from "react-slick";


const Testimonials = () => {
    const sliderRef = useRef(null);
    const [btnBgColor, setBtnBgColor] = useState(false)


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        fade: true, // add fade effect
        cssEase: 'linear' // set fade effect to linear
    };
    const feedbacks = [
        { name: " I was recommended Velolife by a friend, found the advice and service really helpful", img: avatar1 },
        { name: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla a itaque impedit aut nam illo.", img: avatar2 },
        { name: " I was recommended Velolife by a friend, found the advice and service really helpful", img: avatar3 },
        { name: "     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla a itaque impedit aut nam illo.", img: avatar4 },
        { name: " I was recommended Velolife by a friend, found the advice and service really helpful", img: avatar1 },
        { name: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla a itaque impedit aut nam illo.", img: avatar2 },
        { name: " I was recommended Velolife by a friend, found the advice and service really helpful", img: avatar3 },
        { name: "     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla a itaque impedit aut nam illo.", img: avatar4 },




    ];
    const handleNext = () => {
        sliderRef.current.slickNext();
        setBtnBgColor(true)
    };

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
        setBtnBgColor(false)
    };

    return (
        <div className="testimonial-container  p-5 mt-5">
            <p className="testimonial-title ">Testimonials</p>

            <div class="container ">
                <div className="parent-container">

                    <div class="row-1">

                        <div class="col-1">
                            <p></p>
                            <p></p>
                            <p></p>
                            <img src={avatar1} alt="" />
                        </div>
                        <div class="col-2">
                            <p></p>
                            <p></p>
                            <p></p>
                            <img src={avatar2} alt="" />
                        </div>
                    </div>
                    <div class="row-2">
                        <div class="col-1">
                            <p></p>
                            <p></p>
                            <p></p>
                            <img src={avatar3} alt="" />
                        </div>
                        <div className="parent-slider-container-testimonial " id="parent-slider-container-testimonial">
                            <Slider className="my-slider " {...settings} ref={sliderRef} >
                                {feedbacks.map((feedback) => (
                                    <div class="col-2 " >
                                        <p className="par-title-testimonal">John in Oxford</p>
                                        <p className="par-desc-testimonal">
                                            {feedback.name}

                                        </p>
                                        <div className="star-container-col2">
                                            <span>
                                                <svg viewBox="0 0 512 512" style={{ color: "#C7E8EB" }}>
                                                    <path fill="currentColor" stroke="#38878E" strokeWidth="20" d="M507.51 195.55L349.08 172.2 276.3 18.45a10.69 10.69 0 0 0-20.6 0l-72.79 153.75L4.49 195.55a10.7 10.7 0 0 0-5.93 18.19l124.59 121.39-29.42 171.2a10.69 10.69 0 0 0 15.46 11.28l154.24-81.18 154.23 81.18a10.69 10.69 0 0 0 15.47-11.28l-29.42-171.2 124.6-121.39a10.7 10.7 0 0 0-5.93-18.2z" />
                                                </svg>

                                            </span>

                                            <span>

                                                <svg viewBox="0 0 512 512" style={{ color: "#C7E8EB" }}>
                                                    <path fill="currentColor" stroke="#38878E" strokeWidth="20" d="M507.51 195.55L349.08 172.2 276.3 18.45a10.69 10.69 0 0 0-20.6 0l-72.79 153.75L4.49 195.55a10.7 10.7 0 0 0-5.93 18.19l124.59 121.39-29.42 171.2a10.69 10.69 0 0 0 15.46 11.28l154.24-81.18 154.23 81.18a10.69 10.69 0 0 0 15.47-11.28l-29.42-171.2 124.6-121.39a10.7 10.7 0 0 0-5.93-18.2z" />
                                                </svg>
                                            </span>

                                            <span>
                                                <svg viewBox="0 0 512 512" style={{ color: "#C7E8EB" }}>
                                                    <path fill="currentColor" stroke="#38878E" strokeWidth="20" d="M507.51 195.55L349.08 172.2 276.3 18.45a10.69 10.69 0 0 0-20.6 0l-72.79 153.75L4.49 195.55a10.7 10.7 0 0 0-5.93 18.19l124.59 121.39-29.42 171.2a10.69 10.69 0 0 0 15.46 11.28l154.24-81.18 154.23 81.18a10.69 10.69 0 0 0 15.47-11.28l-29.42-171.2 124.6-121.39a10.7 10.7 0 0 0-5.93-18.2z" />
                                                </svg>

                                            </span>

                                            <span>

                                                <svg viewBox="0 0 512 512" style={{ color: "#C7E8EB" }}>
                                                    <path fill="currentColor" stroke="#38878E" strokeWidth="20" d="M507.51 195.55L349.08 172.2 276.3 18.45a10.69 10.69 0 0 0-20.6 0l-72.79 153.75L4.49 195.55a10.7 10.7 0 0 0-5.93 18.19l124.59 121.39-29.42 171.2a10.69 10.69 0 0 0 15.46 11.28l154.24-81.18 154.23 81.18a10.69 10.69 0 0 0 15.47-11.28l-29.42-171.2 124.6-121.39a10.7 10.7 0 0 0-5.93-18.2z" />
                                                </svg>
                                            </span>

                                            <span>
                                                <svg viewBox="0 0 512 512" style={{ color: "#C7E8EB" }}>
                                                    <path fill="currentColor" stroke="#38878E" strokeWidth="20" d="M507.51 195.55L349.08 172.2 276.3 18.45a10.69 10.69 0 0 0-20.6 0l-72.79 153.75L4.49 195.55a10.7 10.7 0 0 0-5.93 18.19l124.59 121.39-29.42 171.2a10.69 10.69 0 0 0 15.46 11.28l154.24-81.18 154.23 81.18a10.69 10.69 0 0 0 15.47-11.28l-29.42-171.2 124.6-121.39a10.7 10.7 0 0 0-5.93-18.2z" />
                                                </svg>

                                            </span>






                                        </div>




                                        <img src={feedback.img} id="testimonial-avatar" alt="" />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div class="col-3">
                            <p></p>
                            <p></p>
                            <p></p>
                            <img src={avatar1} alt="" />

                        </div>
                    </div>
                    <div class="row-3">
                        <div class="col-1">
                            <p></p>
                            <p></p>
                            <p></p>
                            <img src={avatar2} alt="" />
                        </div>
                        <div className="btns-testi-container">
                            <button className={"ml-velo rounded-btn same-btn-design " + (btnBgColor ? 'btn-bg-velo' : 'btn-outline-velo')} onClick={handleNext} >
                                <i class="fa-solid fa-arrow-left"></i>
                            </button>

                            <button className={" rounded-btn same-btn-design  " + (!btnBgColor ? 'btn-bg-velo' : 'btn-outline-velo')} onClick={handlePrevious}>

                                <i class="fa-solid fa-arrow-right "></i>
                            </button>



                        </div>
                        <div class="col-2">
                            <p></p>
                            <p></p>
                            <p></p>
                            <img src={avatar3} alt="" />
                        </div>
                    </div>
                    <div className="testimonial-read-more-btn-container">
                        <button className="read-more-test-btn">Read More</button>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Testimonials