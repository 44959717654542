import React from "react";
import keyFeature from './../assets/keyFeatureImage.png'
import vector from './../assets/vector.svg'
import logo from './../assets/logo.png'
import healthLogo from './../assets/healthLife.png'
import MeetLogo from './../assets/meetLife.png'

import accessories from './../assets/accessories.svg'
import finantialSupport from './../assets/finantialSupport.svg'
import healthSerious from './../assets/healthSerious.svg'
import hospitality from './../assets/hospitality.svg'
import lifeInsurance from './../assets/lifeInsurance.svg'
import incomeProtection from './../assets/incomeProtection.svg'
import mentalHealth from './../assets/mentalHealth.svg'
import publicLiability from './../assets/publicLiability.svg'


const KeyFeatures = () => {
    return (
        <div className="container mt-5">
            <p className="title-typo">Key Features</p>
            <div className="key-feature-container ">
                <div className="key-feature-content-left">
                    <div class="image-wrapper">
                        <img src={keyFeature} className="h-100 w-100 key-feature-bg-image" alt="" />
                        <div class="overlay"></div>

                        <div className="overlay-containers ">
                            <img src={logo} alt="" />



                            <div className="card-container">
                                <div className="velo-card-key ">
                                    <img src={vector} alt="" />
                                    <p className="hero-quote-type  w-60">Accidental Damage  & Theft</p>
                                </div>
                                <div className="velo-card-key">
                                    <img src={accessories} alt="" />
                                    <p className="hero-quote-type  w-50">Accessories& Clothing</p>

                                </div>
                                <div className="velo-card-key">
                                    <img src={publicLiability} alt="" />
                                    <p className="hero-quote-type w-50">Public Liability</p>
                                </div>

                            </div>

                        </div>
                        <div>

                            <button className="buy-btn">Buy Now</button>
                        </div>
                    </div>
                </div>
            <div className="key-feature-content-right">
                <div className="velo-card-keyFeatures first-velo-card">
                    <img src={healthLogo} className="mb-4" alt="" />
                    <div className="velo-card-content">
                        <div className="velo-card-content-container">

                            <div className="title-icon">
                                <img src={lifeInsurance} alt="" />
                                <p className="key-par-typo">Life <br /> Insurance</p>
                            </div>
                            <div className="title-icon">
                                <img src={healthSerious} alt="" />
                                <p className="key-par-typo">Health & Serious <br /> Illness</p>
                            </div>
                            <div className="title-icon">
                                <img src={incomeProtection} alt="" />
                                <p className="key-par-typo">Income <br /> Protection</p>
                            </div>
                        </div>
                        <button className="velo-key-btn">More</button>
                    </div>




                </div>
                <div className="velo-card-keyFeatures second-velo-card">
                    <img src={MeetLogo} alt="" className="mb-4" />
                    <div className="velo-card-content ">
                        <div className="velo-card-content-container">

                            <div className="title-icon">
                                <img src={finantialSupport} alt="" />
                                <p className="key-par-typo">Financial <br /> Support</p>
                            </div>
                            <div className="title-icon">
                                <img src={mentalHealth} alt="" />
                                <p className="key-par-typo">Mental Health & <br /> Wellbeing</p>
                            </div>
                            <div className="title-icon">
                                <img src={hospitality} alt="" />
                                <p className="key-par-typo">Hospitalisation</p>
                            </div>
                        </div>
                        <button className="velo-key-btn">More</button>
                    </div>



                </div>

            </div>

        </div>
        </div>

    )
}

export default KeyFeatures