import React from "react";
import logo from './../assets/logo.png'

const Footer = () => {
    return (
        <div className="footer-container pt-5 pb-3">
            <div className="container">
                <img src={logo} className="logo-small-device" alt="" />
                <div className="footer-main-container">
                    <div className=" left-footer-container">
                        <img src={logo} className="align-self-start logo-web-device" alt="" />
                        <div className="footer-paragraphs">
                            <p>VeloLife is an appointed representative of IWG Ltd who are authorised and regulatedby the Financial Conduct Authority, registered number 310784.</p>
                            <p>  VeloLife are an Exclusive Associate of Vitality Life and Health and do not form part of the same group as Vitality Life and Health</p>
                        </div>




                    </div>
                    <div className="center-footer-container">
                        <p className="menu-footer-title">Menu</p>
                        <div className="menu-container">
                            <p>Home</p>
                            <p>About Us</p>
                            <p>News</p>
                            <p>Get in touch</p>
                            <p>Cycle Insurance</p>
                            <p>Life Insurance</p>
                            <p>Health Insurance</p>
                            <p>Everyday Protect</p>
                        </div>




                    </div>
                    <div className=" right-footer-container">
                        <p className="contact-footer-title">Contact</p>
                        <div className="contact-container h-100">
                            <div className="contact-content">

                                <p>info@velolife.co</p>
                                <p>01908929230</p>
                            </div>
                            <div className="contact-icons">

                                <span>
                                    <i class="fa-brands fa-facebook-f"></i>

                                </span>

                                <span>

                                    <i class="fa-brands fa-instagram"></i>
                                </span>

                                <span>

                                    <i class="fa-brands fa-twitter"></i>
                                </span>

                            </div>
                        </div>






                    </div>
                </div>

                <div className="footer-bottom-container">

                    <div className="footer-details">
                        <p>Copyright VeloLife Limited 2022.</p>

                    </div>

                    <div className="footer-details">
                        <p >Terms & conditions</p>
                        <p>Privacy policy</p>

                    </div>

                    <div className="footer-details ">
                        <p>Cookie policy</p>

                    </div>

                </div>


            </div>
        </div>
    )
}

export default Footer