import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import shape from './../assets/shape.png'
import DatePicker from "react-datepicker";
import './../App.css'
function BikeInsurance() {
    const countries = [
        { name: 'USA', code: '+1', placeholder: "00 - 000 - 0000" },
        { name: 'Canada', code: '+1', placeholder: "00 - 0000 - 000" },
        { name: 'UK', code: '+44', placeholder: "00 - 000 - 0000" },
        // add more country options as needed
    ];
    const [bicycleValueCountry, setbicycleValueCountry] = useState(countries[0])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const bicycleValue = (event) => {
        setbicycleValueCountry(countries.find(c => c.name === event.target.value));
    }
    const validationSchema = Yup.object({
        bikes: Yup.array()
            .of(
                Yup.object({
                    bikeType: Yup.object().required('Required'),
                    bikeMake: Yup.object().required('Required'),
                    bikeValue: Yup.string().required('Required'),
                    bikeSerial: Yup.string().required('Required'),
                })
            ).required('Must have at least one bike'),
            
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        telephone: Yup.string()
            .required('Required'),

        email: Yup.string().email('Invalid email address').required('Required'),
        postcode: Yup.string().required('Required'),

        address1: Yup.string().required('Required'),
        address2: Yup.string().required('Required'),
        city: Yup.object().required('Required'),
        dob: Yup.date().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            bikes: [{
                bikeType: '', bikeMake: '', bikeValue: '', bikeSerial: ''
            }]
            , firstName: '',
            lastName: '',
            telephone: '',
            email: '',
            postcode: '',

            address1: '',

            address2: '',
            city: '',
            dob: selectedDate,
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            alert(JSON.stringify(values, null, 2));
        },
    });

    const handleAddBike = () => {
        formik.setValues({
            ...formik.values,
           
            bikes: [
                ...formik.values.bikes,

                { bikeType: '', bikeMake: '', bikeValue: '', bikeSerial: '' }
            ],

        });
    };

    const handleRemoveBike = (index) => {
        const updatedBikes = [...formik.values.bikes];
        updatedBikes.splice(index, 1);
        formik.setValues({
            ...formik.values,
            bikes: updatedBikes,
        });
    };


    const options = [
        { value: "apple", label: "Apple" },
        { value: "banana", label: "Banana" },
        { value: "cherry", label: "Cherry" },
        { value: "grape", label: "Grape" },
        { value: "orange", label: "Orange" },
    ];
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            outline: state.isFocused ? '' : 'none',
            border: '2px solid #B8F0E4',
            borderRadius: '10px',
            padding: '2px',

            boxShadow: state.isFocused ? '' : provided.boxShadow,

            borderColor: state.isFocused ? '#1ACDA7' : '#1ACDA7', // custom border color on focus
            '&:hover': {
                borderColor: '#1ACDA7', // custom border color on hover
            }, '&:active': {
                background: 'none',
                boxShadow: 'none',
                borderColor: '#1ACDA7',
                outline: "none"
            },

        }),
    };
    const cities = [
        { value: "New York", label: "New York" },
        { value: "London", label: "London" },
        { value: "Barcelona", label: "Barcelona" },

    ];

    function handleDateChange(date) {
        setSelectedDate(date);
    }
    return (
        <div className="bike-insurance-container">
            <div className="container">

                <div className="bike-insuranceHeader ">
                    <div className="bike-insurance-header-textial">
                        <h2>10 Days Free</h2>
                        <p>Bike Insurance</p>


                    </div>
                    <div className="bike-insurance-header-img">

                    <img src={shape} className='h-100 w-100' alt="" />
                    </div>
                    {/* <div className='bike-insurane-img col-md-6'></div> */}

                </div>


                <div className="bike-insurance-content mt-4 mb-5">


                    <form onSubmit={formik.handleSubmit}>
                        {formik.values.bikes.map((bike, index) => (
                            <div key={index}>
                                <div className="insurance-headline-title bike-headline-container mt-3">

                                    <p>Bike {index + 1} Detail</p>

                                    {formik.values.bikes.length > 1 && (
                                        <button type="button" className="remove-cycle-btn" onClick={() => handleRemoveBike(index)}><i class="fas fa-times"></i></button>

                                    )}

                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor={`bikes[${index}].bikeType`}>
                                                Bycicle Type:
                                            </label>
                                            <Select
                                                onBlur={formik.handleBlur}
                                                value={formik.values.bikes[index].bikeType}
                                                options={options}
                                                id={`bikes[${index}].bikeType`}
                                                name={`bikes[${index}].bikeType`}
                                                styles={customStyles}
                                                className=" mt-1"
                                                isClearable
                                                onChange={(selectedOption) => {
                                                    formik.setFieldValue(`bikes[${index}].bikeType`, selectedOption);
                                                }}
                                                placeholder="Please Select the bike "
                                            />

                                            {formik.touched.bikes?.[index]?.bikeType && formik.errors.bikes?.[index]?.bikeType && (
                                                <div>{formik.errors.bikes[index].bikeType}</div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">

                                            <label>
                                                Bycicle Make:
                                            </label>
                                            <Select
                                                onBlur={formik.handleBlur}
                                                value={formik.values.bikes[index].bikeMake}
                                                styles={customStyles}
                                                options={options}
                                                name={`bikes[${index}].bikeMake`}
                                                className="mt-1"
                                                isClearable

                                                onChange={(selectedOption) => {
                                                    formik.setFieldValue(`bikes[${index}].bikeMake`, selectedOption);
                                                }}
                                                placeholder="Please Select the Bycicle Make "
                                            />

                                            {formik.touched.bikes?.[index]?.bikeMake && formik.errors.bikes?.[index]?.bikeMake && (
                                                <div>{formik.errors.bikes[index].bikeMake}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div class="col-md-6">
                                        <label htmlFor="">Bicycle value</label>
                                        <div class="phone-input mt-1">
                                            <select value={bicycleValueCountry.name} onChange={bicycleValue} style={{ width: '20%' }}>
                                                {countries.map((country, index) => (
                                                    <option key={index} value={country.name}> {country.name} </option>
                                                ))}
                                            </select>

                                            <input
                                                type="tel"
                                                placeholder={bicycleValueCountry.placeholder}
                                                style={{ width: '80%' }}
                                                name={`bikes[${index}.bikeValue]`}
                                                value={formik.values.bikes[index].bikeValue}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}

                                            />

                                        </div>
                                        {formik.touched.bikes?.[index]?.bikeValue && formik.errors.bikes?.[index]?.bikeValue && (
                                            <div>{formik.errors.bikes[index].bikeValue}</div>
                                        )}
                                    </div>
                                    <div className="col-md-6">

                                        <label>
                                            Bycicle Serial No:
                                        </label>
                                        <div className="serial-no-container">
                                            <input
                                                type="text"
                                                name={`bikes[${index}].bikeSerial`}
                                                value={formik.values.bikes[index].bikeSerial}
                                                onChange={formik.handleChange}
                                                className="serial-no-input mt-1"
                                                placeholder="you@example.com"
                                                onBlur={formik.handleBlur}
                                            />

                                        </div>
                                        {formik.touched.bikes?.[index]?.bikeSerial && formik.errors.bikes?.[index]?.bikeSerial && (
                                            <div>{formik.errors.bikes[index].bikeSerial}</div>
                                        )}
                                    </div>
                                </div>






                            </div>
                        ))}
                        <div className="btn-signup-container">

                            <button type="button" className="btn-insurance-add-velo btn-insurance-outline-velo mt-3" onClick={handleAddBike}>
                                Add Bike <span><i class="fas fa-plus"></i>
                                </span>
                            </button>
                        </div>
                        <hr />
                        <div className="insurance-headline-title">

                            <p>Personal Information</p>
                        </div>

                        <div className="row mt-1">
                            <div className="col-md-6">

                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder='Your Name'
                                    className="serial-no-input mt-1"
                                />
                                {formik.errors.firstName && formik.touched.firstName && (
                                    <div>{formik.errors.firstName}</div>
                                )}
                            </div>

                            <div className="col-md-6">

                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                    placeholder='Your Last Name'
                                    className='serial-no-input mt-1'
                                />
                                {formik.errors.lastName && formik.touched.lastName && (
                                    <div>{formik.errors.lastName}</div>
                                )}

                            </div>
                        </div>

                        <div className="row mt-1">
                            <div class="col-md-6">
                                <label htmlFor="">Telephone</label>
                                <div class="phone-input mt-1">
                                    <select value={bicycleValueCountry.name} style={{ width: '20%' }}>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country.name}> {country.name} </option>
                                        ))}
                                    </select>
                                    <input type="tel"
                                        placeholder={bicycleValueCountry.placeholder}
                                        style={{ width: '80%' }}
                                        name='telephone'
                                        value={formik.values.telephone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                    />

                                </div>
                                {formik.errors.telephone && formik.touched.telephone && (
                                    <div>{formik.errors.telephone}</div>
                                )}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder='you@example.com'
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    className='serial-no-input mt-1'
                                />
                                {formik.errors.email && formik.touched.email && (
                                    <div>{formik.errors.email}</div>
                                )}


                            </div>
                        </div>







                        <div className="row mt-1">
                            <div className="col-md-6">

                                <label htmlFor="postcode">Postcode</label>
                                <input
                                    type="text"
                                    id="postcode"
                                    name="postcode"
                                    placeholder='00 - 000 - 0000'
                                    onChange={formik.handleChange}
                                    value={formik.values.postcode}
                                    className='serial-no-input mt-1'
                                />
                            </div>
                            {formik.errors.postcode && formik.touched.postcode && (
                                <div>{formik.errors.postcode}</div>
                            )}
                            <div className="col-md-6">

                            </div>
                        </div>


                        <div className="row mt-1">

                            <div className="col-md-6">

                                <label htmlFor="address1">Your Address 1</label>
                                <div className='address-1-container mt-1'>

                                    <textarea
                                        id="address1"
                                        name="address1"
                                        onChange={formik.handleChange}
                                        value={formik.values.address1}
                                    />
                                </div>
                                {formik.errors.address1 && formik.touched.address1 && (
                                    <div>{formik.errors.address1}</div>
                                )}
                            </div>

                            <div className="col-md-6">

                                <label htmlFor="address1">Your Address 2</label>
                                <div className='address-2-container mt-1'>

                                    <textarea
                                        id="address2"
                                        name='address2'
                                        onChange={formik.handleChange}
                                        value={formik.values.address2}
                                    />
                                </div>
                                {formik.errors.address2 && formik.touched.address2 && (
                                    <div>{formik.errors.address2}</div>
                                )}
                            </div>


                        </div>

                        <div className="row mt-1 mb-5">
                            <div className="col-md-6">
                                <label htmlFor="">Your City</label>

                                <Select
                                    onBlur={formik.handleBlur}
                                    styles={customStyles}
                                    value={formik.values.city}
                                    options={cities}
                                    isClearable
                                    onChange={(selectedOption) => {
                                        formik.setFieldValue("city", selectedOption);
                                    }}
                                    className='mt-1'
                                    placeholder="Please Select the category"
                                />
                                {formik.errors.city && formik.touched.city && (
                                    <div>{formik.errors.city}</div>
                                )}


                            </div>

                            <div className="col-md-6">

                                <label htmlFor="dob">Date of Birth:</label>
                                <DatePicker selected={selectedDate} onChange={handleDateChange} placeholder="dd / mm / yyyy" className="date-picker-input mt-1" />

                            </div>
                        </div>
                        <hr />
                        <div className="bike-insurance-footer">
                            <div className="bike-insurance-footer-content">

                                <p>I have read and understood the requirement for my bike to be locked securely with an approved lock relevant to the value of my bike when left unattended away from my home.</p>
                                <p>

                                    By clicking “Sign Up” you agree to be contacted by VeloLife for the purposes of arranging insurance products for you as well as agreement to our <a className='link-bike-insurance' href="#">Policy Terms and Conditions</a> . Our <a className='link-bike-insurance' href="#">privacy policy</a>  can be viewed here.</p>
                            </div>


                            <button type='submit' className="btn-velo ml-velo btn-bg-velo" >Sign Up</button>
                        </div>




                    </form>
                </div >
            </div>
        </div >
    )
}

export default BikeInsurance;