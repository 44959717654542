import React, { useState } from "react"
import { Link } from "react-router-dom";
import "../App.css"
import logo from './../assets/logo.png'
import heroImg from './../assets/hero-img.png'
import { useNavigate } from 'react-router-dom';




const Hero = () => {

    const [showNav, setNav]=useState(false)
    const navigate = useNavigate();

 



    return (
        <div className="hero-section ">

            <div className="container">
                <nav className="navbar d-flex-velo position-relative ">
                    <img src={logo} className="" alt="" />

                    <ul className="d-flex list-unstyled  ">
                        <li className="list-style-none position-relative ">
                            <Link className="text-decoration-none text-dark normal-link-typo" href="#">Services

                                <span className="p-1">

                                    <i className="fa fa-chevron-down position-absolute" style={{ top: '50%', right: '', transform: 'translateY(-50%)' }}></i>
                                </span>

                            </Link>
                        </li>
                        <li>
                            <Link className="text-decoration-none text-dark normal-link-typo" href="#">News & Blog </Link>
                        </li>
                        <li className="position-relative">
                            <Link className="text-decoration-none text-dark normal-link-typo" href="#">About

                                <span className="p-1">

                                    <i className="fa fa-chevron-down position-absolute" style={{ top: '50%', right: '', transform: 'translateY(-50%)' }}></i>
                                </span>
                            </Link>
                        </li>
                        <li><Link className="text-decoration-none text-dark normal-link-typo" href="#">Contact</Link></li>
                        <li><Link className="text-decoration-none btn-velo normal-link-typo btn-outline-velo" href="#">Log In</Link></li>
                    </ul>

                    {/* mobile device navigation  start here */}
                    <div className={showNav?'mobile-device-nav-block':'mobile-device-nav'} >
                        <ul className="small-dev-flex">
                            <div className="mobile-device-header">
                                <img src={logo}  alt="" />
                                <button class="close-icon" onClick={()=>{setNav(!showNav)}}><i class="fa fa-times"></i></button>



                            </div>
                            <div className="mobile-device-content">

                            <p>Services</p>
                            <p>News & Blog</p>
                            <p>About</p>
                            <p>Contact</p>
                            </div>

                        </ul>


                    </div>

                    {/* mobile device navigation end here */}
                    <div className="small-nav">
                        <a href="Log In">Log In</a>
                        <button class="hamburger-icon" onClick={()=>{setNav(!showNav)}}><i class="fa fa-bars"></i></button>


                    </div>




                </nav>

                <div className="hero-container-detail row  mt-4 align-center">
                    <div className=" hero-content align-self-center col-md-5">
                        <p className="hero-large-typo">Complete, quick and simple insurance for you and your bikes</p>
                        <p className="hero-quote-type" >No matter what you ride we’ve got the right cover for you…</p>
                        <div className="btn-group mt-3">
                            <button className="btn-velo ml-velo btn-bg-velo" onClick={()=>{ navigate('/bikeInsuranceQuote')}} >Quick Quote</button>
                            <button className="btn-velo btn-outline-velo" onClick={()=>{navigate('/bikeInsurance')}}>Make a Claim</button>
                        </div>

                    </div>
                    <div className="col-md-1"></div>
                    <img src={heroImg} alt="" className="hero-img col-md-6" />
                </div>


            </div>
        </div>




    )
}

export default Hero