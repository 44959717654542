import React, {  useState } from "react";
import coverSectionImg from './../assets/cover-section-img.png';
import image1 from './../assets/image1.png';

const CoverDetail = (props) => {
    const {windowWidth}=props

    const [coverData, setCoverData] = useState([
        {
            title: 'Theft',
            description:
                'We cover your bike when it’s stored at home, or when you’re out and about as long as you use the right locks that are relevant to your bike – see our lock guide here.',
            imageSrc: coverSectionImg,
            isOpen: false
        },
        {
            title: 'Damage',
            description:
                'Whether the damage is caused through a cycling accident or collision, vandalism or malicious damage, you are covered.',
            imageSrc: image1,
            isOpen: false
        },
        {
            title: 'Public Liability',
            description:
                'Protects you against any damage that you may accidentally cause to a third party, such as a pedestrian or a vehicle.',
            imageSrc: coverSectionImg,
            isOpen: false
        },
        {
            title: 'Replacement Hire',
            description:
                'In the event of something happening to your bike, you can hire an alternative until your bike is fixed or replaced.',
            imageSrc: coverSectionImg,
            isOpen: false
        },
        {
            title: 'Legal Expenses',
            description:
                'If you need to make a claim against a third party, legal expenses insurance covers you for up to £100,000 of legal costs.',
            imageSrc: coverSectionImg,
            isOpen: false
        },
        {
            title: 'Worldwide Cover',
            description:
                'Going away with your bike? Choose worldwide cover for a small additional premium to give you peace of mind that you’re covered wherever you are.',
            imageSrc: coverSectionImg,
            isOpen: false
        },
        {
            title: 'Personal Accident',
            description:
                'There is an add-on to our policy that offers limited cover. See our MultiProtect and Vitality sections to see the full range of outstanding personal protection that we offer.',
            imageSrc: coverSectionImg,
            isOpen: false
        },
        {
            title: 'Accessories',
            description:
                'We cover £2,000 of accessories as standard on our policy. This covers helmets, shoes and anything else that you might have on your bike *excludes anything that contributes to the function of the bike – this is covered under the value of the bike. You can increase the cover if £2,000 isn’t enough for you.',
            imageSrc: coverSectionImg,
            isOpen: false
        },
        {
            title: 'Breakdown Cover',
            description:
                'If your bike is damaged when you’re out, and you’re unable to ride it home/to your onward destination, we’ll pay for a taxi to get you home as long as you’re more than a mile away from home.',
            imageSrc: coverSectionImg,
            isOpen: false
        }
    ])
    const [selectedObj, setSelectedObj] = useState(coverData[0])

    const toggleCard = (index) => {
        setCoverData((prevState) => {
            const newData = [...prevState];
            newData[index].isOpen = !prevState[index].isOpen;
            return newData;
        });
    };




    const handleClick = (coverItem) => {
        setSelectedObj(coverItem)

    }



    return (
        <div className="cover-section-bg mt-5">
            {/* <div className="bg-linear-color"></div> */}
            <div className="container">

                <div className="main-container-cover-section">
                    <div className="position-relative cover-img">

                        <img src={selectedObj.imageSrc} alt={selectedObj.title} className="main-container-cover-section-img h-100 w-100" />
                        <h2 className="cover-detail-position cover-detail-title-typo">{selectedObj.title}</h2>
                    </div>
                    <h2 className="mobile-typo">Cover Details</h2>


                    <div className="container-cover-section-text">
                        {coverData.map((coverItem, index) => (
                            <div className={`velo-card ${coverItem.isOpen ? "velo-card-open" : "velo-card-close"}  ${windowWidth > 768 && (coverItem.title === selectedObj.title ? "selected-velo-card" : "")}`} key={index} onClick={windowWidth > 768 ? () => handleClick(coverItem) : () => toggleCard(index)} >
                                {
                                    (coverItem.isOpen && windowWidth < 768) && (


                                        <img src={coverItem.imageSrc} alt={coverItem.title} />

                                    )
                                }
                                <div>
                                    <div className="header-cover-toggle mt-2">

                                        <p className="cover-title weight-bold ">{coverItem.title}</p>
                                        {
                                            windowWidth < 768 &&
                                            (

                                                <span onClick={() => toggleCard(index)}>
                                                    {!coverItem.isOpen &&

                                                        <i className={`fas fa-plus`}></i>
                                                    }
                                                </span>
                                            )
                                        }
                                    </div>

                                    {(coverItem.isOpen || windowWidth > 768) && (
                                        <div className={coverItem.isOpen && "toggle-content-bottom"} >

                                            <p className="tiny-paragraph-typo" >{coverItem.description}</p>
                                            {(coverData.isOpen || windowWidth < 768) &&


                                                <span onClick={() => toggleCard(index)}>
                                                    <i className={`fas fa-minus`}></i>
                                                </span>
                                            }
                                        </div>

                                    )}
                                </div>
                            </div>
                        ))}
                    </div>




                </div>
            </div>
        </div>
    )
}

export default CoverDetail